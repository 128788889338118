const port = process.env.PORT || '8000';
const dashboardPort = '3001'; // Dashboard to use port 3001

export const getBackendURL = () => {
  // development or testing
  if (!process.env.REACT_APP_STAGE || ['test', 'development'].includes(process.env.REACT_APP_STAGE))
    return `http://${window.location.hostname}:${port}`;
  // staging or produciton
  return `https://api.${window.location.host}`;
};

export const getHost = () => {
  // development or testing
  if (!process.env.REACT_APP_STAGE || ['test', 'development'].includes(process.env.REACT_APP_STAGE))
    return `${window.location.hostname}:${port}`;
  // staging or production
  else return `api.${window.location.host}`;
};

export const getImageURL = (imageLink = '') => {
  imageLink = imageLink.replace(/^\/+/, '');
  if (imageLink.startsWith('http') || imageLink.startsWith('https')) {
    return imageLink;
  }
  switch (process.env.REACT_APP_STAGE) {
    case 'development':
    case 'test':
      return `http://${window.location.hostname}:${port}/${imageLink}`;
    default:
      return `https://api.${window.location.host}/${imageLink}`;
  }
};

export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noreferrer');
};

export const getDashboardURL = () => {
  switch (process.env.REACT_APP_STAGE) {
    case 'development':
    // return 'https://www.dashboard.telemedc.com/';
    case 'test':
      return `http://${window.location.hostname}:${dashboardPort}/`;
    case 'staging':
      return 'https://www.staging.dashboard.telemedc.com/';
    case 'production':
      return 'https://www.dashboard.telemedc.com/';
  }
};
