import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const PREFIX = 'IconLabel';

const classes = {
  background: `${PREFIX}-background`,
  icon: `${PREFIX}-icon`,
  purchase_icon: `${PREFIX}-purchase_icon`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.background}`]: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: '10px',
    padding: theme.spacing(1.25),
    color: theme.palette.common.white,
    width: 'fit-content',
    display: 'flex',
  },

  [`& .${classes.icon}`]: {
    width: '24px',
    height: '24px',
  },
  [`& .${classes.purchase_icon}`]: {
    width: '50px',
    height: '50px',
  },
}));

const IconLabel = (props) => {
  const styledIcon = React.cloneElement(props.icon, { className: props.className || classes.icon });
  return (
    <Root className={classes.background} sx={props.style}>
      {styledIcon}
    </Root>
  );
};

IconLabel.propTypes = {
  icon: PropTypes.element.isRequired,
};

export default IconLabel;
