// ---------------------- USER SIGN UP --------------------------
import {
  // Regions
  AUSTRALIA,
  COUNTRY_CODE_STR_ENUMS,
  ETHNICS_STR,
  GERMANY,
  INDIA,
  IRELAND,
  MALAYSIA,
  SINGAPORE,
  UNITED_KINGDOM,
} from './constants';

export const AVAILABLE_COUNTRIES_BY_DEPLOY_REGION = {
  ['ap-southeast-1']: [SINGAPORE, MALAYSIA],
  ['ap-southeast-2']: [AUSTRALIA],

  ['eu-central-1']: [GERMANY],
  ['eu-west-1']: [IRELAND],
  ['eu-west-2']: [IRELAND, UNITED_KINGDOM],
  ['ap-south-1']: [INDIA],
};

export const DIAL_CODE_BY_COUNTRY = {
  US: '+1',
  AU: '+61',
  NZ: '+64',
  GB: '+44',
  IE: '+353',
  IN: '+91',
  SG: '+65',
  MY: '+60',
  DE: '+49',
};

export const SIGN_UP_FORM_FIELD_STR_ENUMS = {
  [AUSTRALIA]: {
    name: {
      helperText: 'According to Medicare',
    },
    ethnicity: {
      options: ETHNICS_STR.AU,
      placeholder: 'Ethnicity',
    },
    countryOfService: {
      options: {
        [AUSTRALIA]: COUNTRY_CODE_STR_ENUMS[AUSTRALIA],
      },
      label: 'Country of Service',
      placeholder: 'Country of Service',
      defaultCountry: AUSTRALIA,
    },
    phoneNumber: {
      smsEnabled: true,
      defaultCountry: AUSTRALIA,
      onlyCountries: [AUSTRALIA],
    },
    nationalID: {
      label: 'Medicare No.',
      placeholder: 'Medicare No.',
      helperText: 'e.g. 1234 56789 1',
      maxLength: 10,
      excludedChars: /[^0-9]/g,
      pattern: /^[0-9]{10}$/,
      required: true,
      allowEdit: true,
    },
    postcode: {
      label: 'Post Code',
      placeholder: 'Post Code',
      helperText: 'e.g. 4000',
      maxLength: 4,
      required: true,
      excludedChars: /[^0-9]/g,
      pattern: /^[0-9]{4}$/,
    },
    locationBranch: {
      label: 'Branch/Location',
      helperText: 'Your practice branch name or location',
      required: false,
    },
  },
  [SINGAPORE]: {
    name: {
      helperText: 'According to ID/Passport',
    },
    ethnicity: {
      label: 'Ethnicity',
      placeholder: 'Ethnicity',
      options: ETHNICS_STR.SG,
    },
    countryOfService: {
      label: 'Country of Service',
      placeholder: 'Country of Service',
      options: {
        [SINGAPORE]: COUNTRY_CODE_STR_ENUMS[SINGAPORE],
        [MALAYSIA]: COUNTRY_CODE_STR_ENUMS[MALAYSIA],
      },
      defaultCountry: SINGAPORE,
    },
    phoneNumber: {
      smsEnabled: true,
      defaultCountry: SINGAPORE,
      onlyCountries: [SINGAPORE],
    },
    nationalID: {
      label: 'NRIC (Last 4 Characters)',
      placeholder: 'NRIC (Last 4 Characters)',
      helperText: 'e.g. 1234',
      maxLength: 4,
      excludedChars: /[^a-zA-Z0-9]/g,
      pattern: /^[a-zA-Z0-9]{4}$/,
      required: true,
      allowEdit: true,
    },
    postcode: {
      label: 'Post Code',
      placeholder: 'Post Code',
      helperText: 'e.g. 018956',
      maxLength: 6,
      required: false,
      excludedChars: /[^0-9]/g,
      pattern: /^[0-9]{6}$/,
    },
    locationBranch: {
      label: 'Branch/Location',
      helperText: 'Your practice branch name or location',
      required: false,
    },
  },
  [MALAYSIA]: {
    name: {
      helperText: 'According to ID/Passport',
    },
    ethnicity: {
      label: 'Ethnicity',
      placeholder: 'Ethnicity',
      options: ETHNICS_STR.SG,
    },
    countryOfService: {
      label: 'Country of Service',
      placeholder: 'Country of Service',
      options: {
        [MALAYSIA]: COUNTRY_CODE_STR_ENUMS[MALAYSIA],
      },
      defaultCountry: MALAYSIA,
    },
    phoneNumber: {
      smsEnabled: false,
      defaultCountry: MALAYSIA,
      onlyCountries: [MALAYSIA],
    },
    nationalID: {
      label: 'NRIC (Last 4 Characters)',
      placeholder: 'NRIC (Last 4 Characters)',
      helperText: 'e.g. 1234',
      maxLength: 4,
      excludedChars: /[^0-9]/g,
      pattern: /^[0-9]{4}$/,
      required: true,
      allowEdit: false,
    },
    postcode: {
      label: 'Post Code',
      placeholder: 'Post Code',
      helperText: 'e.g. 50670',
      maxLength: 5,
      required: false,
      excludedChars: /[^0-9]/g,
      pattern: /^[0-9]{5}$/,
    },
    locationBranch: {
      label: 'Location/Branch',
      helperText: 'Your practice branch name or location',
      required: false,
    },
  },
  [GERMANY]: {
    name: {
      helperText: 'According to ID/Passport',
    },
    ethnicity: {
      label: 'Ethnicity',
      placeholder: 'Ethnicity',
      options: ETHNICS_STR.DE,
    },
    countryOfService: {
      label: 'Country of Service',
      placeholder: 'Country of Service',
      options: {
        [GERMANY]: COUNTRY_CODE_STR_ENUMS[GERMANY],
      },
      defaultCountry: GERMANY,
    },
    phoneNumber: {
      smsEnabled: false,
      defaultCountry: GERMANY,
      onlyCountries: [GERMANY],
    },
    nationalID: {
      label: 'ID CARD (Personalausweis)',
      placeholder: 'ID CARD (Personalausweis)',
      helperText: 'e.g. LO1X00T47',
      maxLength: 15,
      excludedChars: /[^a-zA-Z0-9]/g,
      pattern: /^[a-zA-Z0-9]{15}$/,
      allowEdit: false,
    },
    postcode: {
      label: 'Post Code',
      placeholder: 'Post Code',
      helperText: 'e.g. 21031',
      required: false,
      maxLength: 5,
      excludedChars: /[^0-9]/g,
      pattern: /^[0-9]{5}$/,
    },
    locationBranch: {
      label: 'Branch/Location',
      helperText: 'Your practice branch name or location',
      required: false,
    },
  },
  [IRELAND]: {
    name: {
      helperText: 'According to ID/Passport',
    },
    ethnicity: {
      label: 'Ethnicity',
      placeholder: 'Ethnicity',
      options: ETHNICS_STR.IRELAND,
    },
    countryOfService: {
      label: 'Country of Service',
      placeholder: 'Country of Service',
      options: {
        [IRELAND]: COUNTRY_CODE_STR_ENUMS[IRELAND],
        [UNITED_KINGDOM]: COUNTRY_CODE_STR_ENUMS[UNITED_KINGDOM],
      },
      defaultCountry: IRELAND,
    },
    phoneNumber: {
      smsEnabled: false,
      defaultCountry: IRELAND,
      onlyCountries: [IRELAND],
    },
    nationalID: {
      label: 'Individual Health Identifier (IHI) number',
      placeholder: 'Individual Health Identifier (IHI) number',
      helperText: 'e.g. 999-999-9999',
      maxLength: 15,
      excludedChars: /[^0-9-]/g,
      allowEdit: false,
    },
    postcode: {
      label: 'Post Code',
      placeholder: 'Post Code',
      helperText: 'e.g. D01 B2CD',
      excludedChars: /[^a-zA-Z0-9 ]/g,
      pattern: /^[a-zA-Z0-9 ]{7}$/,
    },
    locationBranch: {
      label: 'Branch/Location',
      helperText: 'Your practice branch name or location',
      required: false,
    },
  },
  [UNITED_KINGDOM]: {
    name: {
      helperText: 'According to ID Passport',
    },
    ethnicity: {
      label: 'Ethnicity',
      placeholder: 'Ethnicity',
      options: ETHNICS_STR.UNITED_KINGDOM,
    },
    countryOfService: {
      label: 'Country of Service',
      placeholder: 'Country of Service',

      options: {
        [IRELAND]: COUNTRY_CODE_STR_ENUMS[IRELAND],
        [UNITED_KINGDOM]: COUNTRY_CODE_STR_ENUMS[UNITED_KINGDOM],
      },
      defaultCountry: UNITED_KINGDOM,
    },
    phoneNumber: {
      smsEnabled: false,
      defaultCountry: UNITED_KINGDOM,
      onlyCountries: [UNITED_KINGDOM],
    },
    nationalID: {
      label: 'NHS Number',
      placeholder: 'NHS Number',
      helperText: 'e.g. 450 557 7104',
      maxLength: 15,
      excludedChars: /[^0-9]/g,
      pattern: /^[0-9]{10}$/,
      allowEdit: true,
    },
    postcode: {
      label: 'Post Code',

      placeholder: 'Post Code',
      helperText: 'e.g. WC2N 5DU',
      excludedChars: /[^a-zA-Z0-9]/g,
      pattern: /^[a-zA-Z0-9]{6}$/,
    },
    locationBranch: {
      label: 'Branch/Location',
      helperText: 'Your practice branch name or location',
      required: false,
    },
  },
  [INDIA]: {
    name: {
      helperText: 'According to ID Passport',
    },
    ethnicity: {
      label: 'Ethnicity',

      placeholder: 'Ethnicity',
      options: ETHNICS_STR.INDIA,
    },
    countryOfService: {
      label: 'Country of Service',

      placeholder: 'Country of Service',

      options: {
        [INDIA]: COUNTRY_CODE_STR_ENUMS[INDIA],
      },
      defaultCountry: INDIA,
    },
    phoneNumber: {
      defaultCountry: INDIA,
    },
    nationalID: {
      label: 'ID Card Number',
      placeholder: 'ID Card Number', // todo change to Aadhar Card Number? to be confirmed
      helperText: 'e.g. 1111 2222 3333',
      maxLength: 12,
      excludedChars: /[^0-9]/g,
      allowEdit: false,
    },
    postcode: {
      label: 'Post Code',
      placeholder: 'Post Code',
      helperText: 'e.g. 110001',
      maxLength: 6,
      excludedChars: /[^a-zA-Z0-9]/g,
      pattern: /^[a-zA-Z0-9]{6}$/,
    },
    locationBranch: {
      label: 'Branch/Location',
      helperText: 'Your practice branch name or location',
      required: false,
    },
  },
};
