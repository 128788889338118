import { BlueCheckIcon, GreyXIcon, HelpCircleIcon } from '../resources/icons';
import theme from '../styles/theme';
import { AUSTRALIA, SINGAPORE, GERMANY, IRELAND, UNITED_KINGDOM, INDIA, LITE_PACKAGE, FULL_PACKAGE } from './constants';

// ---------------------- Subscription Constants --------------------------
class Content {
  constructor(label, helpIcon, helpDesc, included, excluded) {
    this.label = label; //use this as the title of the content
    this.helpIcon = helpIcon; //use this as the tooltip help icon
    this.helpDesc = helpDesc; //use this as the tooltip help description
    this.included = included; //use this as the component for INCLUDED
    this.excluded = excluded; //use this as the component for EXCLUDED
  }
}

const tick = <BlueCheckIcon />;
const cross = <GreyXIcon />;
const helpIcon = <HelpCircleIcon />;

export const TIER_CONTENTS = [
  new Content(
    'RetinoScan',
    helpIcon,
    "RetinoScan analyses retinal images to accurately identify patients' with Diabetic Retinopathy, Glaucoma and/or Age Related Macular Degeneration.",
    tick,
    cross
  ),

  new Content(
    'RetinoScan Advanced',
    helpIcon,
    'For patients with a disease diagnosis, RetinoScan Advanced determines the disease severity and provide lesion monitoring services.',
    'Suspected Cases',
    cross
  ),

  new Content('Review by Eye Professional', null, ' ', 'Suspected Cases', cross),

  new Content('Comprehensive Patient Report', null, ' ', tick, cross),

  new Content('Class II Medical Device', helpIcon, 'Clinically Validated', tick, cross),

  new Content('Cloud Storage', null, ' ', tick, cross),

  new Content(
    'Patient Data Retention',
    helpIcon,
    "In accordance to HIPPA compliances, all patients' data will be stored and backed up for 15 years",
    '15 years',
    cross
  ),

  new Content('Referral Pathway', null, ' ', 'Coming Soon', 'Coming Soon'),
];

const INCLUDED = 1;
const EXCLUDED = 0;

export const BASIC = [INCLUDED, EXCLUDED, EXCLUDED, INCLUDED, INCLUDED, INCLUDED, INCLUDED, INCLUDED];

export const STANDARD = [INCLUDED, EXCLUDED, INCLUDED, INCLUDED, INCLUDED, INCLUDED, INCLUDED, INCLUDED];

export const PREMIUM = [INCLUDED, INCLUDED, INCLUDED, INCLUDED, INCLUDED, INCLUDED, INCLUDED, INCLUDED];

export const TIER_ORDER = ['Basic', 'Standard', 'Premium'];

export const SUBSCRIPTION_INCLUSIONS_AU = {
  REGION: AUSTRALIA,
  BASIC: BASIC,
  STANDARD: STANDARD,
  PREMIUM: PREMIUM,
};
export const SUBSCRIPTION_INCLUSIONS_DE = {
  REGION: GERMANY,
  BASIC: BASIC,
  STANDARD: STANDARD,
  PREMIUM: PREMIUM,
};

export const SUBSCRIPTION_INCLUSIONS_SG = {
  REGION: SINGAPORE,
  // BASIC: BASIC,
  STANDARD: STANDARD,
  PREMIUM: PREMIUM,
};

export const SUBSCRIPTION_INCLUSIONS_IE = {
  REGION: IRELAND,
  BASIC: BASIC,
  STANDARD: STANDARD,
  PREMIUM: PREMIUM,
};

export const SUBSCRIPTION_INCLUSIONS_GB = {
  REGION: UNITED_KINGDOM,
  BASIC: BASIC,
  STANDARD: STANDARD,
  PREMIUM: PREMIUM,
};

export const SUBSCRIPTION_INCLUSIONS_IN = {
  REGION: INDIA,
  BASIC: BASIC,
  STANDARD: STANDARD,
  PREMIUM: PREMIUM,
};

export const MONTHLY_BILLED = 1;
export const ANNUALLY_BILLED = 2;

export const Permission = {
  CAN_SELECT_DR_RISK: 'select:dr_risk',
  CAN_SELECT_LITE: 'select:lite',
  CAN_SELECT_FULL: 'select:full',
  CAN_SELECT_AUDITOR: 'select:auditor',
};
export const PREPAID = 0;
export const SUBSCRIPTION = 1;
export const SUBSCRIPTION_PACKAGES = {
  Basic: [LITE_PACKAGE],
  Standard: [LITE_PACKAGE],
  Premium: [FULL_PACKAGE],
};
export const RETINOSCAN_PLANS = {
  RETINOSCAN: 'retinoscan',
  RETINOSCAN_PLUS: 'retinoscan_plus',
};
const ACTIVE = 'active';
const UNPAID = 'unpaid';
export const DEACTIVATED = 'canceled';
const FREETRAIL = 'trialing';
const FIRSTBILL = 'first_bill';
const NEXTPLAN = 'next_plan';
const SG = 'sgd';
const AU = 'aud';
const DE = 'eur';
const UK = 'gbp';
const BULK_CREDITS = [250, 500, 1000];
export const CREDITS_OFFERS = {
  [BULK_CREDITS[0]]: 5,
  [BULK_CREDITS[1]]: 10,
  [BULK_CREDITS[2]]: 15,
};
export const REGION_CURRENCIES = {
  [SG]: { text: 'SGD', symbol: '$', gst: '9%' },
  [AU]: { text: 'AUD', symbol: '$', gst: '10%' },
  [DE]: { text: 'EUR', symbol: '€', gst: '19%' },
  [UK]: { text: 'GBP', symbol: '£', gst: '20%' },
};
export const STATUS = {
  [ACTIVE]: 'ACTIVE',
  [UNPAID]: 'PAYMENT FAILED',
  [DEACTIVATED]: 'DEACTIVATED',
  [FREETRAIL]: 'FREE TRIAL',
};
export const STATUS_ERRORS = {
  [ACTIVE]: 'Next bill:',
  [UNPAID]: {
    stage1: 'Your subscription will be deactivated if it is not paid before',
    stage2: "(We'll retry payment every 5 days. Please update your billing information here.)",
  },
  [DEACTIVATED]: 'Your subscription is currently deactivated due to a failed payment',
  [FIRSTBILL]: 'First bill:',
  [FREETRAIL]: 'Next bill:',
};
export const PREPAID_ERRORS = {
  [ACTIVE]: 'Expires:',
};
export const SUBSCRIPTION_ERRORS = {
  [FIRSTBILL]: 'First bill:',
};
export const PLANOPTIONS = [
  {
    title: 'Prepaid',
    subTitle: 'One-time payment, valid 6 months',
    marginRight: theme.spacing(1),
    marginLeft: 0,
    selected: true,
    name: 'prepaid',
    btnText: 'PURCHASE',
    width: '200px',
    disableOption: false,
  },
  {
    title: 'Monthly Subscription',
    subTitle: 'End of month billing cycle',
    marginRight: 0,
    marginLeft: theme.spacing(1),
    selected: false,
    name: 'subscription',
    btnText: 'UPDATE SUBSCRIPTION',
    width: '265px',
    disableOption: false,
  },
];
export const EACH_PLAN_FEATURES = {
  [RETINOSCAN_PLANS.RETINOSCAN]: [
    'Diabetic Retinopathy detection (Non-referable / Referable result)',
    'Exam Report provided to patient by Health Care Professional',
  ],
  [RETINOSCAN_PLANS.RETINOSCAN_PLUS]: [
    'Diabetic Retinopathy, Glaucoma and AMD detection (Non-referable / Referable result)',
    'Diabetic Retinopathy Severity Grading',
    'Facility for Eye Health Practitioner to review and verify results',
    'Exam Report provided to patient by Health Care Professional',
  ],
};
let permissionValues = Object.values(Permission);

export const BASIC_PERMISSIONS = permissionValues.slice(0, -2);
//export const BASIC_PLUS_PERMISSIONS = permissionValues.filter(permission => !permission.includes("full"))
export const STANDARD_PERMISSIONS = [...permissionValues.slice(0, -2), ...permissionValues.slice(-1)];
//export const STANDARD_PLUS_PERMISSIONS = permissionValues
export const PREMIUM_PERMISSIONS = permissionValues;
