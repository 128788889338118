import ReviewsAccordion from '../components/layout/molecules/ReviewsAccordion';
import { AUSTRALIA, GERMANY, INDIA, IRELAND, SINGAPORE, UNITED_KINGDOM } from '../constants/constants';
import theme from '../styles/theme';

//This is for best UI,when the Language is changing apply the suitable styles adjust UI
const PREFIX = 'component';
const componentNames = {
  ExamsByOperator: `${PREFIX}_ExamsByOperator`,
  ExamAccordion: `${PREFIX}_ExamAccordion`,
  ReviewsAccordion: `${PREFIX}_ReviewsAccordion`,
  LargeButton: `${PREFIX}_LargeButton`,
  AddNewField: `${PREFIX}_AddNewField`,
  SmallButton: `${PREFIX}_SmallButton`,
  PatientExams: `${PREFIX}_PatientExams`,
  SubscriptionSelection: `${PREFIX}_SubscriptionSelection`,
  SubscriptionDetails: `${PREFIX}_SubscriptionDetails`,
};
const regionBasedDefaultStyles = {
  en: {
    en_SubscriptionSelection: {
      width: '20%',
      xs_1: 3,
      xs_2: 9,
    },
  },
  de: {
    de_SubscriptionSelection: {
      width: '20%',
      xs_1: 3,
      xs_2: 9,
    },
  },
};
export const customLanguagestyles = {
  en: {
    [componentNames.ExamsByOperator]: {
      date: { width: '20%' },
      patient: { width: '30%', fontWeight: 'bold', textTransform: 'capitalize' },
      practitioner: { width: '28%', fontWeight: 'bold', textTransform: 'capitalize' },
      status: { width: '22%' },
    },
    [componentNames.PatientExams]: {
      label_1: {
        width: 5,
      },
      label_2: {
        width: 7,
      },
    },

    [componentNames.ExamAccordion]: {
      All_Exams: {
        xs: {
          sectionOne: 8,
          sectionTwo: 4,
        },
        xl: {
          sectionOne: 9,
          sectionTwo: 3,
        },
      },
    },
    [componentNames.ReviewsAccordion]: {
      sectionOne: 9.5,
      sectionTwo: 2.5,
    },
    [componentNames.LargeButton]: {
      width: '25ch',
    },
    [componentNames.AddNewField]: {
      lg: {
        input: 10,
        button: 2,
      },
      xl: {
        input: 10.7,
        button: 1.3,
      },
      fixedButton: '25%',
    },
    [componentNames.SmallButton]: {
      width: '5ch',
    },
    [componentNames.SubscriptionSelection]: {
      [SINGAPORE]: regionBasedDefaultStyles.en.en_SubscriptionSelection,
      [AUSTRALIA]: regionBasedDefaultStyles.en.en_SubscriptionSelection,
      [GERMANY]: regionBasedDefaultStyles.en.en_SubscriptionSelection,
      [IRELAND]: regionBasedDefaultStyles.en.en_SubscriptionSelection,
      [UNITED_KINGDOM]: regionBasedDefaultStyles.en.en_SubscriptionSelection,
      [INDIA]: regionBasedDefaultStyles.en.en_SubscriptionSelection,
    },
    [componentNames.SubscriptionDetails]: {
      ['resumeSubscription']: {
        padding: theme.spacing(1, 3),
        marginInline: theme.spacing(1.5),
      },
    },
  },
  de: {
    [componentNames.ExamsByOperator]: {
      date: { width: '23%' },
      patient: { width: '27%', fontWeight: 'bold', textTransform: 'capitalize' },
      practitioner: { width: '24%', fontWeight: 'bold', textTransform: 'capitalize' },
      status: { width: '26%' },
    },
    [componentNames.PatientExams]: {
      label_1: {
        width: 6,
      },
      label_2: {
        width: 6,
      },
    },

    [componentNames.ExamAccordion]: {
      All_Exams: {
        xs: {
          sectionOne: 7.7,
          sectionTwo: 4.3,
        },
        xl: {
          sectionOne: 7.7,
          sectionTwo: 4.3,
        },
      },
    },
    [componentNames.ReviewsAccordion]: {
      sectionOne: 9,
      sectionTwo: 3,
    },
    [componentNames.LargeButton]: {
      width: '30ch',
    },
    [componentNames.AddNewField]: {
      lg: {
        input: 9.5,
        button: 2.5,
      },
      xl: {
        input: 10.2,
        button: 1.8,
      },
      fixedButton: '32%',
    },
    [componentNames.SmallButton]: {
      width: '7ch',
    },
    [componentNames.SubscriptionSelection]: {
      [SINGAPORE]: {
        width: '20%',
        xs_1: 3.5,
        xs_2: 8.5,
      },
      [AUSTRALIA]: regionBasedDefaultStyles.de.de_SubscriptionSelection,
      [GERMANY]: regionBasedDefaultStyles.de.de_SubscriptionSelection,
      [IRELAND]: regionBasedDefaultStyles.de.de_SubscriptionSelection,
      [UNITED_KINGDOM]: regionBasedDefaultStyles.de.de_SubscriptionSelection,
      [INDIA]: regionBasedDefaultStyles.de.de_SubscriptionSelection,
    },
    [componentNames.SubscriptionDetails]: {
      ['resumeSubscription']: {
        padding: theme.spacing(1, 1),
        marginInline: theme.spacing(1.5),
      },
    },
  },
};
