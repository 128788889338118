import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

import Loading from '../organisms/Loading/Loading';
import { useSelector } from 'react-redux';
import theme from '../../../styles/theme';

const getVariantStyle = (theme, variant) => {
  switch (variant) {
    case 'accent':
      return {
        backgroundColor: theme.palette.primary.main,
        fontWeight: 'bold',
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        boxShadow: theme.shadows[0],
        color: 'white',
      };
    case 'disabled':
      return { backgroundColor: theme.palette.primary.disabled };
    case 'warning':
      return {
        backgroundColor: 'white',
        color: theme.palette.error.dark,
        boxShadow: theme.shadows[1],

        '&:hover': {
          backgroundColor: theme.palette.error.dark,
          color: 'white',
        },
      };
    case 'noBG':
      return {
        backgroundColor: 'hsla(120, 100%, 50%, 0)',

        '&:hover': {
          backgroundColor: 'hsla(120, 100%, 50%, 0)',
          color: theme.palette.button.dark,
        },
      };
    case 'primary':
      return {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        '&.Mui-disabled': {
          color: theme.palette.text.hint,
          backgroundColor: '#D5D4DE',
        },
      };
    case 'text':
      return {
        background: 'none',
        color: theme.palette.text.primary,
        '&:hover': {
          backgroundColor: 'inherit',
        },
        minHeight: 0,
      };
    case 'link':
      return {
        color: theme.palette.primary.main,
        background: 'none',
        '&:hover': {
          backgroundColor: 'inherit',
        },
        fontWeight: 400,
      };
    default:
      return {};
  }
};

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    !['customVariant', 'customWidth', 'fullWidth', 'noMargin', 'regionPadding', 'noPadding'].includes(prop),
})(({ theme, customVariant, fullWidth, customWidth, noMargin, noPadding, regionPadding, regionMargin }) => ({
  width: fullWidth ? '100%' : 'auto',
  height: '40px',
  minWidth: customWidth,
  maxWidth: '100%',
  margin: noMargin ? 0 : theme.spacing(1),
  marginInline: regionMargin,
  padding: noPadding ? 0 : regionPadding,
  textTransform: 'capitalize',
  fontWeight: '700',
  fontFamily: theme.typography.fontFamily,
  borderRadius: '10px',
  backgroundColor: theme.palette.background.dark,

  '&:hover': {
    backgroundColor: theme.palette.button.light,
  },

  ...getVariantStyle(theme, customVariant),
}));

const SmallButton = (props) => {
  const { styles } = useSelector((state) => state.handlingTranslation);
  const [customPadding, setCustomPadding] = useState({ regionPadding: theme.spacing(1, 2) });
  const {
    customVariant,
    fullWidth,
    customWidth = styles.component_SmallButton?.width,
    className,
    children,
    showLoading,
    ...muiButtonProps
  } = props;
  useEffect(() => {
    switch (props.btnLabelType) {
      case 'resumeSubscription':
        setCustomPadding({
          regionPadding: styles.component_SubscriptionDetails.resumeSubscription.padding,
        });
        break;
      default: {
        setCustomPadding({ regionPadding: theme.spacing(1, 2) });
      }
    }
  }, []);

  return (
    <StyledButton
      {...muiButtonProps}
      customWidth={customWidth}
      fullWidth={fullWidth}
      color="primary"
      customVariant={customVariant}
      className={className}
      endIcon={showLoading ? null : muiButtonProps.endIcon}
      startIcon={showLoading ? null : muiButtonProps.startIcon}
      disabled={showLoading || muiButtonProps.disabled}
      regionPadding={customPadding.regionPadding}
    >
      {showLoading ? <Loading size="xs" /> : children}
    </StyledButton>
  );
};

export default SmallButton;
