import React from 'react';
import { t } from 'i18next';

import _ from 'lodash';

import {
  AMD_LITE,
  DR_FULL,
  DR_LITE,
  GC_LITE,
  GLAUCOMA_CDR,
  RETINO_SCAN,
  RETINO_SCAN_ADV,
  RETINO_SCAN_ALL_SERVICES,
  RETINO_SCAN_SERVICES,
} from '../constants/constants';
import { LR_RESULT_ENUMS } from '../constants/results';
import { EMAIL, EMAIL_REGEX, PHONE_NUMBER } from '../constants/validations';
import { calculateWorstCasesByImages, separateLeftRightImages } from './calculations';
import {
  BASIC_PERMISSIONS,
  PREMIUM_PERMISSIONS,
  REGION_CURRENCIES,
  STANDARD_PERMISSIONS,
  TIER_ORDER,
} from '../constants/subscriptions';
import { uiVisibleTexts } from '../translation/visibleTexts';

export const getValuesGenerateKeys = (arg) => {
  const replacingSymbols = [
    ' ',
    '\n',
    '’',
    ',',
    "'",
    '-',
    ':',
    '.',
    '=',
    '?',
    '&',
    '*',
    '%',
    '$',
    '#',
    '@',
    '!',
    '^',
    '(',
    ')',
    '+',
    '[',
    ']',
    '{',
    '}',
    '|',
    '/',
  ];
  let result = { name: arg };
  replacingSymbols.map((symbol) => {
    result.name = { ...result }.name?.replaceAll(symbol, '_');
  });
  return result.name;
};
export const transferValueGetKey = (text) => {
  const fetchingUiVisibleTexts = uiVisibleTexts.en;
  let keyName;
  Object.keys(fetchingUiVisibleTexts).forEach((key) => {
    if (fetchingUiVisibleTexts[key] === text) {
      keyName = key;
    } else if (!keyName) {
      keyName = text;
    }
  });
  return keyName;
};
export const applyTranslationToValues = () => {
  const translationWords = { ...uiVisibleTexts.en };
  Object.keys(translationWords).map(
    (textkey) => (translationWords[textkey] = t(transferValueGetKey(translationWords[textkey])))
  );
  return translationWords;
};

export const setLocalStorageWithExpire = (key, value, secondsToLive) => {
  const now = new Date();
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    timeToLive: now.getTime() + secondsToLive * 1000,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageWithExpire = (key) => {
  const itemString = localStorage.getItem(key);
  if (!itemString) {
    return null;
  }
  const item = JSON.parse(itemString);
  const now = new Date();
  if (now.getTime() > item.timeToLive) {
    // If the item is expired, delete the item from storage
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const sortMeetingByTime = (meetingList, isDescending = false) => {
  return [...meetingList].sort((meeting1, meeting2) => {
    const result = new Date(meeting1?.start_datetime).getTime() - new Date(meeting2?.start_datetime).getTime();
    return isDescending ? -result : result;
  });
};

export const sortMeetingByName = (meetingList, isDescending = false) => {
  return isDescending ? [...meetingList].sort().reverse() : [...meetingList].sort();
};

/**
 * conditionally wrap an element
 * @param {boolean} condition the condition to determine whether to wrap the element
 * @param {boolean} visible the condition to determine whether the element is visible
 * @param {ReactElement || DetailedReactHTMLElement || JSX.Element}  wrapper ConditionalWrapper component
 * @param {ReactElement || String} children the element to be wrapped
 * @returns {ReactElement || DetailedReactHTMLElement} wrapped children if condition is true otherwise return the children itself
 */
export const ConditionalWrapper = ({ condition, visible = true, wrapper, children }) => {
  if (!visible) {
    return null;
  }
  if (condition) {
    return React.cloneElement(wrapper, null, children);
  }
  return children;
};

export const allotObjectToTier = (planName, basicObj, standardObj, premiumObj) => {
  if (planName?.includes('Basic')) {
    return basicObj;
  } else if (planName?.includes('Standard')) {
    return standardObj;
  } else if (planName?.includes('Premium')) {
    return premiumObj;
  } else {
    // TODO error
  }
};

export const dictCheck = (dict, key) => {
  if (key in dict) {
    return dict[key];
  } else {
    return null;
  }
};

export const determineToolTipTitleContent = (requiredPermissions) => {
  let body = 'Upgrade your subscription if you wish to select this service';
  if (requiredPermissions.every((permission) => BASIC_PERMISSIONS.indexOf(permission) > -1)) {
    return ['Basic Plan required!', body];
  } else if (requiredPermissions.every((permission) => STANDARD_PERMISSIONS.indexOf(permission) > -1)) {
    return ['Standard Plan required!', body];
  }
  // else if (requiredPermissions.every(permission => (BASIC_PLUS_PERMISSIONS && STANDARD_PLUS_PERMISSIONS).indexOf(permission) > -1)) {
  //     return ["Plus or Premium Plan required!", body]
  // }
  else if (requiredPermissions.every((permission) => PREMIUM_PERMISSIONS.indexOf(permission) > -1)) {
    return ['Premium Plan required!', body];
  } else {
    return null;
  }
};

export const normalizePlanName = (name) => {
  let normalizedName = '';
  if (name) {
    if (name.includes('Basic')) {
      normalizedName = 'Basic';
    } else if (name.includes('Standard')) {
      normalizedName = 'Standard';
    } else if (name.includes('Premium')) {
      normalizedName = 'Premium';
    } else {
      return 'Not-Supported';
    }

    return normalizedName;
  }
};

export const getIndexInTierOrder = (planName) => {
  return TIER_ORDER.indexOf(planName);
};

/**
 * Sort service results based on retinoScan or retinoScan+
 * @param {array} serviceResults the results to categorize
 * @returns {array} an array contains sorted retinoScan or retinoScan+ service results
 */
export const categorizeServiceResults = (serviceResults = []) => {
  return serviceResults.reduce((acc, curr) => {
    if (RETINO_SCAN_ALL_SERVICES.includes(curr.service_type)) {
      const serviceIndex = RETINO_SCAN_ALL_SERVICES.indexOf(curr.service_type);
      // sort retinoScan services in GLAUCOMA_CDR and DR FULL
      acc[serviceIndex] = curr;
    }
    return acc;
  }, []);
};

/**
 * Combine service results into a single array
 * @param {array} serviceResults the results to combine
 * @returns {array} an array containing all service results
 */
export const combineServiceResults = (serviceResults = []) => {
  // Define the desired order of service types
  const order = serviceResults.some((result) => result.service_type === 5) ? [5, 9, 7, 4] : [3, 9, 7, 4];

  // Sort the serviceResults array based on the order array
  const sortedServiceResults = serviceResults
    .filter((el) => order.includes(el.service_type))
    .sort((a, b) => {
      return order.indexOf(a.service_type) - order.indexOf(b.service_type);
    });

  // Combine the sorted results into the first array of the two
  return sortedServiceResults.reduce((combinedResults, result) => {
    combinedResults.push(result);
    return combinedResults;
  }, []);
};
/**
 * process images to get formatted service results
 * @param {array} examEyeImages the images with service results
 * @returns {array} an array contains formatted service results
 */
export const getServiceResultsByImages = (examEyeImages) => {
  if (_.isEmpty(examEyeImages)) return [];
  const leftRightIndexMap = {
    0: LR_RESULT_ENUMS.LEFT,
    1: LR_RESULT_ENUMS.RIGHT,
  };

  const separatedLeftRightImages = separateLeftRightImages(examEyeImages);
  const separatedWorstImageGradingServices = Object.values(separatedLeftRightImages).map((leftOrRightImages) =>
    calculateWorstCasesByImages(leftOrRightImages)
  );
  let serviceResults = separatedWorstImageGradingServices.reduce(
    (serviceResults, worstImageGradingServices, currentIndex) => {
      worstImageGradingServices.map((imageGradingService) => {
        let { id, service_type, ...results } = imageGradingService;
        let serviceResult = serviceResults.find((serviceResult) => serviceResult.service_type === service_type);
        if (serviceResult) {
          serviceResult.laterality.push({
            laterality: leftRightIndexMap[currentIndex],
            ...results,
          });
        } else {
          serviceResult = {
            service_type: imageGradingService.service_type,
            laterality: [
              {
                laterality: leftRightIndexMap[currentIndex],
                ...results,
              },
            ],
          };
          serviceResults.push(serviceResult);
        }
      });
      return serviceResults;
    },
    []
  );
  return serviceResults.filter((serviceResult) =>
    [DR_LITE, DR_FULL, AMD_LITE, GC_LITE, GLAUCOMA_CDR].includes(serviceResult.service_type)
  );
};

/**
 * Creates processed results object for ease of use.
 * @param {object} service Service object
 * @returns {object} Object containing serviceType, leftEyeResult and rightEyeResult properties
 */
export const processServiceResults = (service = {}) => {
  return {
    serviceType: service.service_type,
    leftEyeResult: service.laterality?.find((laterality) => laterality.laterality === LR_RESULT_ENUMS.LEFT),
    rightEyeResult: service.laterality?.find((laterality) => laterality.laterality === LR_RESULT_ENUMS.RIGHT),
  };
};

export const toTrunc = (number, precision = 2) => {
  let regex = RegExp(`^\\d+(?:\\.\\d{0,${precision}})?`);
  if (typeof number === 'number') {
    return number.toString().match(regex)?.[0];
  }
};

/* Check if string is valid UUID */
export const checkIfValidUUID = (str) => {
  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(str);
};

/**
 * Strip string from pattern and limit to maxLength
 * @param str
 * @param pattern
 * @param maxLength
 * @returns {string}
 */
export const stripString = (str = '', pattern, maxLength) => {
  return str.replace(pattern, '').slice(0, maxLength);
};

export const isEmailOrPhoneNumber = (str, excludeDialCode = false) => {
  // Regular expression to match phone numbers
  let phoneRegex;
  if (excludeDialCode) {
    phoneRegex = /^[0-9]{1,4}[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,4}$/;
  } else {
    phoneRegex = /^[+]?[(]?[0-9]{1,4}[)]?[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,4}$/;
  }
  // Check if the input string matches either of the regex patterns
  if (EMAIL_REGEX.test(str)) {
    return EMAIL;
  } else if (phoneRegex.test(str)) {
    return PHONE_NUMBER;
  }
};

/**
 * Parses error responses returned from the backend API.
 *
 * @param {object} body - The error response body from the backend.
 * @param {string} defaultMsg - The default error message to use if no specific message is found.
 * @returns {string} Parsed error message.
 */
export function parseError(body, defaultMsg) {
  // Use the provided default message if available, or a generic one.
  let message = defaultMsg || 'An error occurred, please try again later.';

  let field = '';

  // If the error response or error array is missing, return the default message.
  if (!body || !body.errors || !body.errors.length) {
    return defaultMsg;
  }

  // Extract the error message and field from the first error in the array.
  message = body?.errors?.[0]?.message;
  field = body?.errors?.[0]?.field;

  // Remove square brackets from the message, if present.
  if (message.startsWith('[') && message.endsWith(']')) {
    message = message.slice(1, -1);
  }

  // Convert underscores in field name to spaces.
  const fieldName = field ? field.replace(/_/g, ' ') : '';

  // If both the message and field name are available, format the error message with the field name.
  if (message && fieldName) {
    return `${fieldName.toUpperCase()}: ${message}`;
  }

  return message || defaultMsg;
}

/**
 * Check if a specific cookie exists in the current document.
 *
 * @param {string} cookieName - The name of the cookie to check for.
 * @returns {boolean} True if the cookie exists, false otherwise.
 */
export function checkCookie(cookieName) {
  // Split all cookies into an array and iterate through them.
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    // If the current cookie starts with the specified name, the cookie exists.
    if (cookie.startsWith(cookieName + '=')) {
      return true; // Cookie found
    }
  }
  // If no matching cookie is found, return false.
  return false; // Cookie not found
}
export const concatinateCurrencySymbol = (values, planOptions, plans) => {
  const copy_planOptions = [...planOptions];
  const obj = { ...plans };
  values.forEach((element) => {
    obj.curr = element.stripe_price.currency;
    copy_planOptions[0].description = '';
    copy_planOptions[0].description_1 = `Price listed is in ${REGION_CURRENCIES[element.stripe_price.currency]?.text}${REGION_CURRENCIES[element.stripe_price.currency]?.symbol} and does not include GST. Applicable taxes will be added.`;
    copy_planOptions[0].description_2 = 'Choose your preferred prepaid amount:';
    copy_planOptions[1].description =
      '* A minimum subscription period of 3 months is required for first time purchase.';
    copy_planOptions[1].description_1 = `Price listed is in ${REGION_CURRENCIES[element.stripe_price.currency]?.text}${REGION_CURRENCIES[element.stripe_price.currency]?.symbol} and does not include GST. Applicable taxes will be added.`;
    copy_planOptions[1].description_2 = 'Choose your preferred monthly plan*:';
  });
  return { ...obj, planOptions: copy_planOptions };
};
////// Calculate the GST+Basic Amout //////
export const calculateGST = (baseAmount, gst, index) => {
  const gstAmount = parseFloat(baseAmount) * (parseFloat(gst) / 100);
  const basicAmount = baseAmount - gstAmount;
  const totalAmount = baseAmount + gstAmount;
  const arr = [gstAmount, totalAmount, baseAmount];

  // const arr = [gstAmount, parseFloat(baseAmount), baseAmount];
  return arr[index];
};
export const calculateDiscountedPrice = (originalPrice, discountRate) => {
  const discountAmount = originalPrice * (discountRate / 100);
  const finalPrice = originalPrice - discountAmount;
  return finalPrice;
};
export const btnDisableFunc = (data) => {
  // if (data?.subscription_usages && data.subscription_usages.length > 0) {
  //   return data.subscription_usages.some(
  //     (subsc) => subsc.subscription.status === 'active' || subsc.subscription.status === 'trialing'
  //   );
  // } else {
  //   return false;
  // }
  return false;
};

// export const splitingBillingSummary = (data) => {
//   if (!!data.test_mode) {
//     return data;
//   } else {
//     let copyData = { ...data, prepaidBtn_disable: false };
//     if (!!data?.prepaid_expiry_date) {
//       const checkPrepaidExpiration = isPlanExpired(data.prepaid_expiry_date);
//       if (checkPrepaidExpiration) {
//         copyData = { ...copyData, prepaid_usages: [], prepaid_expiry_date: '' };
//       } else if (!checkPrepaidExpiration) {
//         const arr = [];
//         arr.length = 2;
//         copyData = { ...copyData, prepaid_usages: [] };
//         let copyPrepaid = data.prepaid_usages
//           .sort((a, b) => b.exam_balance - a.exam_balance)
//           .filter((obj) => {
//             if (obj.exam_balance > 0) {
//               return obj;
//             }
//           })
//           .sort((a, b) => a.exam_balance - b.exam_balance);
//         copyPrepaid.length = 5;
//         copyData.prepaid_usages = copyPrepaid;
//         // if (copyPrepaid.length < 6) {
//         //   copyData.prepaidBtn_disable = true;
//         // }
//       }
//     }
//     if (data?.subscription_usages.length > 0) {
//       let copyCanceledSubscription = data.subscription_usages.filter((obj) => obj.subscription.status == 'canceled');
//       let copyActiveSubscription = data.subscription_usages.filter((obj) => obj.subscription.status == 'active');
//       let copyTrailingSubscription = data.subscription_usages.filter((obj) => obj.subscription.status == 'trialing');
//       copyCanceledSubscription = copyCanceledSubscription.sort(
//         (a, b) => b.subscription.current_period_end - a.subscription.current_period_end
//       );
//       copyCanceledSubscription = copyCanceledSubscription.sort(
//         (a, b) => b.subscription.current_period_end - a.subscription.current_period_end
//       );
//       copyCanceledSubscription.length = 1;
//       copyActiveSubscription.length = 1;
//       if (copyCanceledSubscription || copyActiveSubscription || copyTrailingSubscription) {
//         copyData = { ...copyData, subscription_usages: [] };
//         if (copyActiveSubscription.length > 0) {
//           copyData.subscription_usages = [...copyData.subscription_usages];
//           copyActiveSubscription.map((obj) => {
//             copyData.subscription_usages.push(obj);
//           });
//         }
//         if (copyTrailingSubscription.length > 0) {
//           copyData.subscription_usages = [...copyData.subscription_usages];
//           copyTrailingSubscription.map((obj) => {
//             copyData.subscription_usages.push(obj);
//           });
//         }
//         if (copyCanceledSubscription.length > 0) {
//           copyData.subscription_usages = [...copyData.subscription_usages];
//           copyCanceledSubscription.map((obj) => {
//             copyData.subscription_usages.push(obj);
//           });
//         }
//       }
//     }
//     return copyData;
//   }
// };
export const splitBillingDetails = (data) => {
  if (!!data.test_mode) {
    return data;
  } else {
    const recentBillingStatus = [];

    let destructureData = {
      ...data,
      subscription: { active: [], canceled: [], trialing: [], unpaid: [] },
      prepaid: [],
      subscription_errorText: '',
    };
    if (!data?.subscription_usages && !data?.prepaid_usages) {
      return destructureData;
    } else if (!isPlanExpired(data?.prepaid_expiry_date) || data?.subscription_usages.length > 0) {
      if (data?.prepaid_usages.length > 0) {
        if (isPlanExpired(data.prepaid_expiry_date)) {
          destructureData.prepaid = [];
        } else if (!isPlanExpired(data.prepaid_expiry_date) && data?.prepaid_usages.length > 0) {
          let copy = data.prepaid_usages
            .sort((a, b) => new Date(b.invoice.effective_at) - new Date(a.invoice.effective_at))
            .filter((obj) => obj.exam_balance > 0 && !!obj.is_active);
          // copy.length = 5;
          copy = copy.sort((a, b) => new Date(a.exam_balance) - new Date(b.exam_balance));
          destructureData.prepaid = copy;
        }
      }

      if (data?.subscription_usages.length > 0) {
        const copy = data.subscription_usages.sort(
          (a, b) => new Date(b.subscription.start_date) - new Date(a.subscription.start_date)
        );
        if (copy.length > 0) {
          copy.map((billing, index, arr) => {
            if (billing.subscription.status === 'canceled') {
              recentBillingStatus.push(billing);
              destructureData.subscription.active = [recentBillingStatus[0]];
              // destructureData.subscription.active.push(billing);
              if (billing.subscription?.current_period_end) {
                if (!!isPlanExpired(billing.subscription?.current_period_end)) {
                  destructureData.subscription.active = [];
                }
              }
              return;
            } else if (billing.subscription.status === 'unpaid' || billing.subscription.status === 'past_due') {
              if (!!isPlanExpired(billing.subscription?.current_period_end)) {
                recentBillingStatus.push(billing);
                destructureData.subscription.active = [recentBillingStatus[0]];
                // destructureData.subscription.active.push(billing);
              }
              return;
            } else if (billing.subscription.status === 'trialing') {
              if (!isPlanExpired(billing.subscription?.trial_end)) {
                recentBillingStatus.push(billing);

                destructureData.subscription.active = [recentBillingStatus[0]];
                destructureData.subscription_errorText =
                  'Your current Monthly Subscription trialing plan is active, If you want to change please contact us at info@telemedc.com';

                // destructureData.subscription.active.push(billing);
              }
              return;
            } else if (
              billing.subscription.status === 'active' &&
              !isPlanExpired(billing.subscription?.current_period_end)
            ) {
              recentBillingStatus.push(billing);
              destructureData.subscription.active = [recentBillingStatus[0]];
              destructureData.subscription_errorText =
                'Your current Monthly Subscription Plan is active, If you want to change please contact us at info@telemedc.com';
              // destructureData.subscription.active.push(billing);
              return;
            } else {
              destructureData.subscription.active = [];
            }
          });
        }
      }
      return destructureData;
    } else {
      return destructureData;
    }
  }
};

export function isPlanExpired(expiryDate) {
  const now = new Date(); // Get the current date and time
  const expirationDate = new Date(expiryDate); // Convert the expiry date string to a Date object
  // Compare the current date with the expiration date
  if (now > expirationDate) {
    return true; // The plan is expired
  } else {
    return false; // The plan is still active
  }
}
